import React from 'react'
import Navbar from '../../Components/Navbar'
import Swiper from '../../Components/Swiper'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'
function Manualtesting() {
    return (
        <div>
            {/* <Navbar /> */}
            <div>
                <section
                    style={{
                        background: "linear-gradient(270deg,rgb(25 151 236) 0,#000 100%)",
                    }}
                    className ="hero-banner new-hero vh-500px header finisher-header text-center text-md-start text-white overflow-hidden"
                >
                    <div className ="container z-3 text-center">
                        <div
                            className ="row d-flex justify-content-center align-items-center flex-row-reverse flex-lg-row"
                        >
                            <div>
                                <h1
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                    className ="primary-header"
                                >
                                    Manual <span className ="primary-color">Testing</span>
                                </h1>
                                <p
                                    className ="text-light fs-6 text-capitalize fw-light"
                                    data-aos="fade-up"
                                    data-aos-duration="700"
                                >
                                    Average Salary For Manual Testing Engineers In India - Rs.12 Lakhs Per Annum
                                </p>
                                <nav
                                    data-aos="fade-up"
                                    data-aos-duration="800"
                                    style={{
                                        '--bs-breadcrumb-divider': 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E")'
                                    }}

                                    aria-label="breadcrumb"
                                >
                                    <ol className ="breadcrumb justify-content-center">
                                        <li className ="breadcrumb-item"><Link to= "/">Home</Link></li>
                                        <li className ="breadcrumb-item"><Link to= "/domains">Domains</Link></li>
                                        <li
                                            className ="breadcrumb-item active text-white"
                                            aria-current="page"
                                        >
                                            Manual Testing
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <div id="artifical" className ="coursesList">
                    <div className ="container-fluid ">
                        <p className ="fw-semibold display-6 text-center text-capitalize justify-content-center linear-gradient-text pt-5"
                            data-aos="flip-up" data-aos-duration="900" data-aos-delay="300">
                            About Manual Testing Course</p>
                        <div className ="mt-4 text-center text-capitalize g-3">
                            <p data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">Through ATIT’s Manual Testing Training You'll Embark On A Journey To Master The Art Of Manual Testing. Throughout This Course, You'll Delve Into The Core Principles Of Software Testing, Encompassing Test Planning, Test Case Design, Execution, And Defect Management. By Actively Participating In Real-World Examples And Hands-On Labs, You'll Develop A Profound Understanding Of A Variety Of Testing Techniques, Including Functional, Regression, And Exploratory Testing Join ATIT As We Guide You Towards Becoming A Skilled Manual Software Tester With Practical Experience That Will Pave The Way For A Rewarding And Fulfilling Career In The Field Of Software Quality Assurance.
                            </p>
                            <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">Experience the unparalleled edge of
                                real-time project training – exclusive to us, as no
                                other
                                institutes
                                can match our offering.</p>
                        </div>
                    </div>
                    <div className ="container">
                        <section className ="course-includes">
                            <div className ="container-fluid">
                                <h2 className ="text-center justify-content-center align-content-center linear-gradient-text pt-5"
                                    data-aos="flip-left" data-aos-duration="500" data-aos-delay="600">
                                    Course
                                    Includes</h2>
                                <div className ="feature-grid row ">
                                    <div className ="feature col-lg-4" data-aos="fade-right" data-aos-duration="500"
                                        data-aos-delay="300">
                                        <div className ="feature-item">
                                            <i className ="ri-macbook-fill"></i>
                                            <p>75 Hours of Sessions</p>
                                        </div>
                                    </div>
                                    <div className ="feature col-lg-4" data-aos="fade-down zoom-in" data-aos-duration="700"
                                        data-aos-delay="300">
                                        <div className ="feature-item">
                                            <i className ="ri-time-fill"></i>
                                            <p>Flexible Schedules</p>
                                        </div>
                                    </div>
                                    <div className ="feature col-lg-4" data-aos="fade-left" data-aos-duration="500" data-aos-delay="300">
                                        <div className ="feature-item">
                                            <i className ="ri-customer-service-fill"></i>
                                            <p>24/7 Lifetime Support</p>
                                        </div>
                                    </div>
                                    <div className ="feature col-lg-4" data-aos="fade-right" data-aos-duration="500"
                                        data-aos-delay="300">
                                        <div className ="feature-item">
                                            <i className ="ri-star-fill"></i>
                                            <p>Certification Oriented Curriculum</p>
                                        </div>
                                    </div>
                                    <div className ="feature col-lg-4" data-aos="fade-down zoom-in" data-aos-duration="700"
                                        data-aos-delay="300">
                                        <div className ="feature-item">
                                            <i className ="ri-send-plane-2-fill"></i>
                                            <p>FREE Demo on Request</p>
                                        </div>
                                    </div>
                                    <div className ="feature col-lg-4" data-aos="fade-left" data-aos-duration="500" data-aos-delay="300">
                                        <div className ="feature-item">
                                            <i className ="ri-user-fill"></i>
                                            <p>One-on-One Doubt Clearing</p>
                                        </div>
                                    </div>
                                    <div className ="feature col-lg-4" data-aos="fade-up" data-aos-duration="600" data-aos-delay="400">
                                        <div className ="feature-item">
                                            <i className ="ri-thumb-up-fill"></i>
                                            <p>Real-time Project Use cases</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className ="container-fluid mt-5 py-4">
                        <p className ="fw-semibold display-6 text-center text-capitalize linear-gradient-text justify-content-center linear-gradient-text"
                            data-aos="flip-right" data-aos-delay="500" data-aos-duration="500">
                            Manual Testing Course Content</p>
                        <div className ="mt-4 text-center text-capitalize g-3" data-aos="fade-up" data-aos-delay="300"
                            data-aos-duration="500">
                            <p>Manual Testing Is A Crucial Aspect Of Software Quality Assurance, Ensuring That Software Applications Meet Their Intended Functionality And Are Free Of Defects Before Reaching Users. In This Course, You Will Explore The Key Concepts, Techniques, And Best Practices In Manual Testing Through A Structured Curriculum That Includes Lectures, Hands-On Exercises, Real-World Examples, And Interactive Discussions.
                            </p>
                        </div>
                    </div>

                    <div className ="container mt-3" id="placement-accordion">
                        <div className ="row">
                            <div className ="col-md-6" data-aos="fade-right" data-aos-delay="600" data-aos-duration="600">
                                <div className ="accordion" id="accordion1">
                                    <div className ="accordion-item">
                                        <h2 className ="accordion-header">
                                            <button className ="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                                aria-controls="panelsStayOpen-collapseOne">
                                                Introduction to Software Testing
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className ="accordion-collapse collapse" data-bs-parent="#accordion1">
                                            <div className ="accordion-body">
                                                <ul>
                                                    <li>What is software testing?</li>
                                                    <li>Importance of software testing in the SDLC (Software Development Life Cycle)</li>
                                                    <li>Types of testing: manual vs. automated</li>
                                                    <li>Common testing terminology and concepts</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className ="accordion-item">
                                        <h2 className ="accordion-header">
                                            <button className ="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo">
                                                Fundamentals of Manual Testing
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseTwo" className ="accordion-collapse collapse" data-bs-parent="#accordion1">
                                            <div className ="accordion-body">
                                                <ul>
                                                    <li>Testing principles and fundamentals</li>
                                                    <li> Testing life cycle</li>
                                                    <li>Testing levels (unit testing, integration testing, system </li>
                                                    <li>testing, user acceptance testing)</li>
                                                    <li> Testing types (functional, non-functional, etc.)</li>
                                                    <li> Test Planning and Test Strategy</li>
                                                    <li>Test planning process</li>
                                                    <li> Creating a test strategy</li>
                                                    <li> Test plan documentation</li>
                                                    <li>Test environment setup</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className ="accordion-item">
                                        <h2 className ="accordion-header">
                                            <button className ="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree">
                                                Test Case Design
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseThree" className ="accordion-collapse collapse" data-bs-parent="#accordion1">
                                            <div className ="accordion-body">
                                                <ul>
                                                    <li>Test case development process</li>
                                                    <li> Test case design techniques (equivalence partitioning, boundary value analysis, decision tables, etc.)</li>
                                                    <li> Writing effective and clear test cases</li>
                                                    <li>   Traceability between requirements and test cases</li>
                                                    <li> Test Execution and Defect Management</li>
                                                    <li>Test execution process</li>
                                                    <li>  Executing test cases manually</li>
                                                    <li> Defect identification and reporting</li>
                                                    <li> Defect life cycle</li>
                                                    <li>  Regression testing</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className ="col-md-6" data-aos="fade-left" data-aos-delay="600" data-aos-duration="600">
                                <div className ="accordion" id="accordion2">
                                    <div className ="accordion-item">
                                        <h2 className ="accordion-header">
                                            <button className ="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                Functional Testing
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className ="accordion-collapse collapse" data-bs-parent="#accordion2">
                                            <div className ="accordion-body">
                                                <ul>
                                                    <li>Understanding functional requirements</li>
                                                    <li>Black-box testing techniques</li>
                                                    <li>Positive and negative testing</li>
                                                    <li>Smoke testing and sanity testing</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className ="accordion-item">
                                        <h2 className ="accordion-header">
                                            <button className ="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                Non-Functional Testing
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className ="accordion-collapse collapse" data-bs-parent="#accordion2">
                                            <div className ="accordion-body">
                                                <ul>
                                                    <li> Performance testing</li>
                                                    <li>Load testing</li>
                                                    <li>Stress testing</li>
                                                    <li>Usability testing</li>
                                                    <li>Compatibility testing</li>
                                                    <li>Security testing</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className ="accordion-item">
                                        <h2 className ="accordion-header">
                                            <button className ="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                Test Documentation and Reporting
                                            </button>
                                        </h2>
                                        <div id="collapseSix" className ="accordion-collapse collapse" data-bs-parent="#accordion2">
                                            <div className ="accordion-body">
                                                <ul>
                                                    <li>  Test summary reports</li>
                                                    <li> Defect reports</li>
                                                    <li>Test metrics and measurement</li>
                                                    <li>Test closure activities</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className ="container my-3 pt-3">
                        <div className ="mt-4">
                            <h2 className ="linear-gradient-text fw-semibold text-center mb-2" data-aos="flip-right"
                                data-aos-duration="500" data-aos-delay="600">Training Options </h2>
                            <p className ="fw-medium text-center mt-3" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
                                Choose your own comfortable learning
                                experience.</p>
                            <p className ="text-center  text-center fw-normal" data-aos="fade-up" data-aos-duration="800"
                                data-aos-delay="400">Whether You Choose To Learn Online Or Offline, We're Excited To Have You As Part Of Our Manual Testing Community And Look Forward To Helping You Advance Your Career In The World Of Manual Testing!</p>
                        </div>
                    </section>
                    <section>
                        <div className ="container-fluid">
                            <div className ="row">
                                <div className ="col-md-6">
                                    <div className ="card mx-auto mt-4">
                                        <div className ="card-body d-flex flex-column">
                                            <div>
                                                <h2 className ="text-center" data-aos="fade-up" data-aos-duration="700"
                                                    data-aos-delay="500"><span className ="text-success">Online</span> Classes
                                                </h2>
                                                <div className ="d-flex text-center justify-content-center mb-3 mt-2"
                                                    data-aos="flip-right" data-aos-delay="600" data-aos-duration="700">
                                                    <Link to="/contact" className ="btn btn-success rounded-pill my-2" >Join Now</Link>
                                                </div>
                                                <p className ="about class-content text-center" data-aos="fade-up"
                                                    data-aos-duration="800" data-aos-delay="600">In Today's Digital Age, Manual Testing Has Become An Essential Practice For Organizations Looking To Streamline Their Software Development And IT Operations. This Online Course Is Designed To Provide You With The Knowledge And Skills Needed To Excel In The World Of Manual Testing, All From The Comfort Of Your Own Home Or Office.
                                                </p>
                                                <div className ="" data-aos="fade-up" data-aos-duration="900" data-aos-delay="700">
                                                    <p className ="fs-2 fw-semibold">Course Highlights:</p>
                                                    <p>✔️ Flexible Learning: Learn at your own pace, and access course
                                                        materials
                                                        24/7.
                                                    </p>
                                                    <p>✔️ Expert Instructors: Our experienced instructors will guide you
                                                        through the
                                                        latest
                                                        Manual
                                                        Testing concepts, tools, and best practices.</p>
                                                    <p>✔️ Interactive Learning: Engage in discussions, collaborate with
                                                        fellow
                                                        learners,
                                                        and
                                                        work
                                                        on
                                                        hands-on projects to reinforce your understanding.</p>
                                                    <p>Join us online and embark on a journey to master Manual Testing,
                                                        ensuring you
                                                        are
                                                        well-equipped
                                                        to meet the demands of the ever-evolving tech industry.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className ="col-md-6">
                                    <div className ="card mt-4 mx-auto">
                                        <div className ="card-body d-flex flex-column">
                                            <div>
                                                <h2 className ="text-capitalize text-center" data-aos="fade-up" data-aos-duration="700"
                                                    data-aos-delay="500"><span className ="text-danger">Offline</span>
                                                    classes
                                                </h2>

                                                <div className ="d-flex text-center justify-content-center mb-3 mt-2"
                                                    data-aos="flip-right" data-aos-delay="600" data-aos-duration="700">
                                                    <Link to="/contact" className ="btn btn-success rounded-pill my-2" >Join Now</Link>
                                                </div>
                                                <div className ="mt-3">
                                                    <p className ="about class-content text-center"
                                                        data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">
                                                        In A World Dominated By Screens And Virtual Interactions, There's Something Special About Coming Together In Person To Learn And Collaborate. Our Manual Testing Class Is Designed To Provide You With A Hands-On, Immersive Experience That Will Empower You To Become A Manual Testing Expert.</p>
                                                    <div data-aos="fade-up" data-aos-duration="900" data-aos-delay="700">
                                                        <p className ="fs-2 fw-semibold">Course Highlights:</p>
                                                        <p>✔️ Face-to-Face Interaction: Interact directly with instructors
                                                            and fellow
                                                            learners,
                                                            fostering a
                                                            supportive and collaborative learning environment.</p>
                                                        <p>✔️ Networking: Build valuable professional connections with your
                                                            peers,
                                                            instructors, and
                                                            industry
                                                            experts.</p>
                                                        <p>✔️ Physical Resources: Access to physical resources, tools, and
                                                            equipment for
                                                            a
                                                            more
                                                            comprehensive programming experience & learning experience</p>

                                                        <p>Join us offline concepts and practices, and
                                                            leave with
                                                            the
                                                            skills and confidence to excel in the field of Manual Testing.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className ="mt-5">
                            <p
                                className ="fw-semibold display-6 text-center text-capitalize justify-content-center linear-gradient-text" data-aos="flip-up" data-aos-delay="500" data-aos-duration="500">
                                For Corporates</p>
                            <p style={{
                                textAlign: 'center',
                                padding: '20px',
                                WebkitTransition: 'fade-down 600ms ease-out',
                                transition: 'fade-down 600ms ease-out',
                            }}
                            >Empower your team with new skills to Enhance their performance and productivity</p>
                        </div>
                    </section>
                    <div className ="container">
                        <div className ="row">
                            <div className ="col-md-6">
                                <p className ="text-center fw-semibold fs-5">Corporate Training</p>
                                <div className ="text-capitalize pt-3">
                                    <p className ="d-flex"><i className ="fa-regular pt-2 fa-hand-point-right fa-fade mx-2 fs-6"
                                        style={{
                                            color: '#051838',
                                        }}
                                    ></i>Customized course curriculum as per your team’s specific
                                        needs
                                    </p>
                                    <p className ="d-flex"><i className ="fa-regular pt-2 fa-hand-point-right fa-fade mx-2 fs-6"
                                        style={{
                                            color: '#051838',
                                        }}
                                    ></i>Training delivery through self-Paced videos
                                        liveInstructor-led
                                        training
                                        through online, on-premise at ATIT or your office facility </p>

                                    <p className ="d-flex"><i className ="fa-regular pt-2 fa-hand-point-right fa-fade mx-2 fs-6"
                                        style={{
                                            color: '#051838',
                                        }}
                                    ></i>Resources such as slides, demos, exercises, and answer keys
                                        included</p>
                                    <p className ="d-flex"><i className ="fa-regular pt-2 fa-hand-point-right fa-fade mx-2 fs-6"
                                        style={{
                                            color: '#051838',
                                        }}
                                    ></i>Complete guidance on obtaining certification</p>
                                    <p className ="d-flex pt-2"><i className ="fa-regular pt-2 fa-hand-point-right fa-fade mx-2 fs-6"
                                        style={{
                                            color: '#051838',
                                        }}
                                    ></i>Complete practical demonstration and discussions on industry
                                        use
                                        cases</p>

                                </div>
                            </div>
                            <div className ="col-md-6">
                                <p className ="text-center fw-semibold fs-5"> Served 120+ Corporates</p>
                                <div className ="d-flex justify-content-center align-items-center">
                                    <img src="./../../assets/img/logos.jpg" className ="img-fluid mt-2 justify-content-center mx-2 " alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <section>
                        <div className ="container">
                            <div className ="my-5">
                                <p className ="fw-semibold display-6 text-center text-capitalize justify-content-center linear-gradient-text"
                                    data-aos="flip-up" data-aos-delay="500" data-aos-duration="500">
                                    Manual Testing
                                    Certification</p>
                                <p style={{
                                    textAlign: 'center',
                                    padding: '20px',
                                    transition: 'fade-up 500ms ease-out',
                                    transitionDelay: '500ms',
                                    transitionDuration: '500ms',
                                }}
                                >Show the world that you have pursued one
                                    of the
                                    best
                                    in the industry</p>
                                <img id="cerimg" src="./../../assets/img/ceri.jpg" alt="" />
                            </div>
                        </div>
                    </section>

                </div>
            </div>
            <div><Swiper /></div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

export default Manualtesting
